import React from 'react'
import classNames from 'classnames'

import Footer from './footer'
import Header from './header'

interface Props {
  children: React.ReactNode
  location: Location
  bg: string
  variant: string
  className: string
}

const Wrapper = ({ children, location, bg, variant, className }: Props) => {
  const classes = [{[`bg-${bg}`]: bg}, variant, className]
  return (
    <div id="wrapper" className={classNames(classes)}>
      <Header location={location} />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Wrapper
